import React, { useEffect } from "react";
import { PrimaryButton } from "../buttons/primary-button";
import { Col, Row, Container } from "react-bootstrap";
import { LogoText } from "../../components/shared/logo";

export default function ContactUsBanner() {
  return (
    <div className="banner">
      <Container className="no-gutters">
        <Row>
          <Col>
            <div className="banner__container">
              <div className="banner__background"></div>
              <h3>
                Become a <LogoText /> partner today
              </h3>
              <div className="banner__title text-lightgray">
                If you're interested in{" "}
                <strong className="text-white">discussing a new project</strong>{" "}
                or <strong className="text-white">any of our sevices</strong>{" "}
                please get in touch.
              </div>
              <PrimaryButton to="/contactus">Get in touch</PrimaryButton>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
