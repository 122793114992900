import React from 'react';
import { Link } from '@reach/router';

interface IPrimaryButtonProps {
  onClick: () => void;
  color: string;
  iconTransition: boolean;
  to: string;
}

export const PrimaryButton: React.FC<IPrimaryButtonProps> = ({
  color = '',
  children,
  onClick,
  className = '',
  iconTransition = false,
  to,
}) => {
  const iconTransitionClass = iconTransition ? 'icon-transition' : '';
  return to ? (
    <Link
      to={to}
      className={`btn btn-primary  ${iconTransitionClass}  ${className}`}
      style={{
        color: color,
      }}
      onClick={onClick}
    >
      {children}
    </Link>
  ) : (
    <button
      className={`btn btn-primary  ${iconTransitionClass}  ${className}`}
      style={{
        color: color,
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
