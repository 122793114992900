import React, { useEffect, useState } from "react";

function BackToTop({ inOffset = 50, bottomOffset = 50 }) {
  const [backToTopVisible, setBackToTopVisible] = useState(false);
  const [backToTopAtBottom, setBackToTopAtBottom] = useState(0);

  useEffect(() => {
    window.onscroll = event => {
      if (
        document.body.scrollTop > inOffset ||
        document.documentElement.scrollTop > inOffset
      ) {
        setBackToTopVisible(true);
      } else {
        setBackToTopVisible(false);
      }

      if (window.innerWidth <= 768) {
        bottomOffset = 270;
      }

      if (
        window.innerHeight + window.scrollY + bottomOffset >=
        document.body.offsetHeight
      ) {
        setBackToTopAtBottom(
          Math.abs(
            -window.innerHeight +
              document.body.offsetHeight -
              window.scrollY -
              bottomOffset
          )
        );
      } else {
        setBackToTopAtBottom(false);
      }
    };
  });
  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <button
      className={`btn btn-round back-to-top ${
        backToTopVisible ? "visible" : ""
      }`}
      onClick={() => backToTop()}
      style={{ bottom: backToTopAtBottom }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 23.8 13.7"
        xmlSpace="preserve"
      >
        <polyline className="st0" points="0.9,12.8 11.9,1.8 22.9,12.8 " />
      </svg>
    </button>
  );
}

export default BackToTop;
