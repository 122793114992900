import React, { Component } from "react";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import ContactUsBanner from "../shared/contact-us-banner";
import BackToTop from "../buttons/back-to-top";

interface IFooterProps {
  hideBanner?: boolean;
}

export class Footer extends Component<IFooterProps> {
  state = { currentYear: new Date().getFullYear() };

  render() {
    const renderBanner = () =>
      this.props.hideBanner ? null : <ContactUsBanner />;

    return (
      <>
        <BackToTop bottomOffset={60} />
        {renderBanner()}
        <footer>
          <Container>
            <Row>
              <Col sm={6}>
                <div className="utility-link">
                  <Link to="/privacy-policy">
                    <small>Privacy Policy</small>
                  </Link>
                  <Link to="/terms-conditions">
                    <small>Terms &amp; Conditions</small>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="copyright">
                  <small>
                    All Rights Reserved Copyright © {this.state.currentYear}{" "}
                    Diviv Group
                  </small>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
       
      </>
    );
  }
}

declare global {
  interface Window {
    hbspt: any;
    reqwest: any;
  }
}
