import React, { Component } from "react";
import LayoutContactUs from "../components/layouts/layout-contact-us";
import ContactUsForm from "../components/shared/contact-us-form";
import { IoLogoLinkedin, IoLogoFacebook } from "react-icons/io";
import { Row, Col } from "react-bootstrap";
import SEO from "../components/shared/seo";
import instagramIcon from "../images/instagram-icon.svg";
import facebookIcon from "../images/facebook-icon.svg";
import linkedinIcon from "../images/linkedin-icon.svg";
import { DivivGoogleMap } from "../components/shared/google-map";
import MobileNav from "../components/shared/mobile-nav";

export default class contactus extends Component {
  render() {
    return (
      <LayoutContactUs>
        <SEO title="Contact us" />

        <div
          id="contact-us-content"
          className="page-layout"
          data-menu-class="inverted"
        >
          <div className="container">
            <Row>
              <Col lg={12}>
                <h6>Contact us</h6>
                <h2>Looking forward to hearing from you</h2>
              </Col>

              <Col sm={12} md={12} lg={8}>
                <div className="contact-form-wrapper">
                  <ContactUsForm />
                </div>
              </Col>

              <Col sm={12} md={12} lg={4}>
                <div className="contact-info-wrapper">
                  <ul className="contact-info-wrapper__address">
                    <li>Address:</li>
                    <li className="location">
                      {" "}
                      165 / 15 Aberdeen St, (Corner of Pier St and Aberdeen St),
                      Perth WA 6000
                    </li>
                    <li>
                      {" "}
                      <a
                        href="https://goo.gl/maps/3AwjAKDbhKfAzEw19"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Get directions
                      </a>
                    </li>
                  </ul>

                  <div className="telephone">
                    {" "}
                    <a href="tel:1300875978">1300 875 978</a>
                  </div>

                  <div className="contact-info-wrapper__social-media">
                    <p>FOLLOW US</p>

                    <div className="social-icon-wrapper">
                      <a
                        href="https://www.instagram.com/divivgroup/"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon__link"
                      >
                        <img
                          src={instagramIcon}
                          style={{ width: "15px", height: "15px" }}
                          alt="Follow Diviv on Instagram"
                        />
                      </a>

                      <a
                        href="https://www.facebook.com/divivgroup/"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon__link"
                      >
                        <img
                          src={facebookIcon}
                          style={{ height: "15px" }}
                          alt="Follow Diviv on Facebook"
                        />{" "}
                      </a>

                      <a
                        href="https://www.linkedin.com/company/17890171"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon__link"
                      >
                        <img
                          src={linkedinIcon}
                          style={{ width: "15px" }}
                          alt="Follow Diviv on Linkedin"
                        />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <MobileNav />
      </LayoutContactUs>
    );
  }
}
